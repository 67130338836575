import _tslib from "tslib";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = _tslib;

var Semaphore =
/** @class */
function () {
  function Semaphore(_maxConcurrency) {
    this._maxConcurrency = _maxConcurrency;
    this._queue = [];

    if (_maxConcurrency <= 0) {
      throw new Error("semaphore must be initialized to a positive value");
    }

    this._value = _maxConcurrency;
  }

  Semaphore.prototype.acquire = function () {
    var _this = this;

    var locked = this.isLocked();
    var ticket = new Promise(function (r) {
      return _this._queue.push(r);
    });
    if (!locked) this._dispatch();
    return ticket;
  };

  Semaphore.prototype.runExclusive = function (callback) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
      var _a, value, release;

      return tslib_1.__generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4
            /*yield*/
            , this.acquire()];

          case 1:
            _a = _b.sent(), value = _a[0], release = _a[1];
            _b.label = 2;

          case 2:
            _b.trys.push([2,, 4, 5]);

            return [4
            /*yield*/
            , callback(value)];

          case 3:
            return [2
            /*return*/
            , _b.sent()];

          case 4:
            release();
            return [7
            /*endfinally*/
            ];

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  Semaphore.prototype.isLocked = function () {
    return this._value <= 0;
  };

  Semaphore.prototype.release = function () {
    if (this._maxConcurrency > 1) {
      throw new Error("this method is unavailabel on semaphores with concurrency > 1; use the scoped release returned by acquire instead");
    }

    if (this._currentReleaser) {
      var releaser = this._currentReleaser;
      this._currentReleaser = undefined;
      releaser();
    }
  };

  Semaphore.prototype._dispatch = function () {
    var _this = this;

    var nextConsumer = this._queue.shift();

    if (!nextConsumer) return;
    var released = false;

    this._currentReleaser = function () {
      if (released) return;
      released = true;
      _this._value++;

      _this._dispatch();
    };

    nextConsumer([this._value--, this._currentReleaser]);
  };

  return Semaphore;
}();

exports.default = Semaphore;
export default exports;