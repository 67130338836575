import _Mutex from "./Mutex";
import _Semaphore from "./Semaphore";
import _withTimeout from "./withTimeout";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withTimeout = exports.Semaphore = exports.Mutex = void 0;
var Mutex_1 = _Mutex;
Object.defineProperty(exports, "Mutex", {
  enumerable: true,
  get: function () {
    return Mutex_1.default;
  }
});
var Semaphore_1 = _Semaphore;
Object.defineProperty(exports, "Semaphore", {
  enumerable: true,
  get: function () {
    return Semaphore_1.default;
  }
});
var withTimeout_1 = _withTimeout;
Object.defineProperty(exports, "withTimeout", {
  enumerable: true,
  get: function () {
    return withTimeout_1.withTimeout;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      withTimeout = exports.withTimeout,
      Semaphore = exports.Semaphore,
      Mutex = exports.Mutex;